/** @format */

import React, { useState } from 'react';
import Navbar from '../../Components/NavBar';
import {
  Box,
  TextField,
  Grid,
  Typography,
  Button,
  Input,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import { useNewNetworkMutation } from '../../Store/Api/Main';

const NewNetwork = () => {
  const [netwrokName, setNetwrokName] = useState('');
  const [country, setCountry] = useState('');
  const [website, setWebsite] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [image, setImage] = useState(null);
  const [addNetwork] = useNewNetworkMutation();
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    console.log('object', file);
    setImage(file);
  };
  const handlenetwrokSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('networkName', netwrokName);
      formData.append('country', country);
      formData.append('websiteUrl', website);
      formData.append('description', description);
      formData.append('type', type);

      // Ensure 'image' is a valid File object before appending it
      if (image) {
        formData.append('image', image);
      } else {
        console.log('No image selected');
      }

      // Log FormData contents
      for (let pair of formData.entries()) {
        console.log(pair[0] + ': ' + pair[1]);
      }

      // Send FormData to the server using the addNetwork function (which should use fetch/axios)
      const response = await addNetwork(formData);

      // Check if the response is successful
      if (response) {
        console.log('Network submission successful:', response);

        setNetwrokName('');
        setCountry('');
        setWebsite('');
        setDescription('');
        setType('');
        setImage(null);
      } else {
        console.log('Network submission failed:', response);
      }
    } catch (error) {
      console.log('Error during network submit:', error);
    }
  };
  return (
    <>
      {' '}
      <Box
        style={{
          padding: '20px',
          backgroundColor: '#331D48',
          height: '100%',
        }}>
        <Navbar
          mainHeading='Admin Dashboard'
          isAdmin={true}
        />

        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexDirection: { xs: 'column', sm: 'row', md: 'row' },
            my: 4,
          }}>
          <Grid
            item
            xs={12}
            sx={{ textAlign: 'center' }}>
            <Typography
              variant='h4'
              sx={{
                color: '#f0f0f0',
                fontWeight: 'bold',
                fontSize: { xs: '1.3rem', sm: '1.5rem', md: '2rem' },
                textAlign: 'center',
              }}>
              Add New Network
            </Typography>
          </Grid>

          {/* Form Fields */}
        </Grid>
      </Box>
      <Box
        style={{
          padding: '20px',
          // backgroundColor: '#331D48',
          height: '100%',
        }}>
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: { xs: 'column', sm: 'row', md: 'row' },
            my: 4,
          }}>
          <Grid
            item
            xs={12}
            sm={4}>
            <TextField
              label='Network Name'
              name='name'
              value={netwrokName}
              onChange={(e) => {
                setNetwrokName(e.target.value);
              }}
              fullWidth
              required
              variant='outlined'
              sx={{
                mb: 4,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#331D48', // Default border color
                  },
                  '&:hover fieldset': {
                    borderColor: '#331D48', // Border color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#331D48', // Border color when focused
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#331D48', // Label color
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#331D48 ', // Label color when focused
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}>
            <TextField
              label='Country'
              name='country'
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              fullWidth
              required
              variant='outlined'
              sx={{
                mb: 4,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#331D48', // Default border color
                  },
                  '&:hover fieldset': {
                    borderColor: '#331D48', // Border color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#331D48', // Border color when focused
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#331D48', // Label color
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#331D48 ', // Label color when focused
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}>
            <TextField
              label='Website URL'
              name='website'
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              fullWidth
              required
              variant='outlined'
              sx={{
                mb: 4,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#331D48', // Default border color
                  },
                  '&:hover fieldset': {
                    borderColor: '#331D48', // Border color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#331D48', // Border color when focused
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#331D48', // Label color
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#331D48 ', // Label color when focused
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}>
            <TextField
              label='Description'
              name='description'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              required
              variant='outlined'
              multiline
              rows={4}
              sx={{
                mb: 4,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#331D48', // Default border color
                  },
                  '&:hover fieldset': {
                    borderColor: '#331D48', // Border color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#331D48', // Border color when focused
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#331D48', // Label color
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#331D48 ', // Label color when focused
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}>
            <TextField
              label='Type'
              name='type'
              value={type.toLocaleUpperCase()}
              onChange={(e) => setType(e.target.value)}
              fullWidth
              required
              variant='outlined'
              sx={{
                mb: 4,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#331D48', // Default border color
                  },
                  '&:hover fieldset': {
                    borderColor: '#331D48', // Border color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#331D48', // Border color when focused
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#331D48', // Label color
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#331D48 ', // Label color when focused
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}>
            <FormControl
              fullWidth
              sx={{ mb: 4 }}>
              {/* <InputLabel htmlFor='image-upload'>Upload Image</InputLabel> */}
              <Input
                id='image-upload'
                name='image'
                type='file'
                onChange={handleImageChange}
              />
              <FormHelperText sx={{ color: '#f0f0f0' }}>
                {image ? image.name : 'Choose an image'}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{ textAlign: 'center' }}>
            <Button
              variant='contained'
              color='primary'
              onClick={handlenetwrokSubmit}
              sx={{ width: '200px', mt: 4, backgroundColor: '#331D48' }}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default NewNetwork;
