/** @format */

import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Input,
  FormControl,
  FormHelperText,
} from "@mui/material";
import {
  useHomeImage1Mutation,
  useHomeImage2Mutation,
  useHomeImage3Mutation,
  useHomeImage4Mutation,
} from "../../Store/Api/Main";

const ContentForHome = () => {
  const [homeImage1, setHomeImage1] = useState(null);
  const [homeImage2, setHomeImage2] = useState(null);
  const [homeImage3, setHomeImage3] = useState(null);
  const [homeImage4, setHomeImage4] = useState(null);

  const handleImageChange1 = (e) => setHomeImage1(e.target.files[0]);
  const handleImageChange2 = (e) => setHomeImage2(e.target.files[0]);
  const handleImageChange3 = (e) => setHomeImage3(e.target.files[0]);
  const handleImageChange4 = (e) => setHomeImage4(e.target.files[0]);

  const [UploadImage1] = useHomeImage1Mutation();
  const [UploadImage2] = useHomeImage2Mutation();
  const [UploadImage3] = useHomeImage3Mutation();
  const [UploadImage4] = useHomeImage4Mutation();

  const handleSubmit1 = async (e) => {
    e.preventDefault();

    if (!homeImage1) {
      alert("Please select a file first!");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("image", homeImage1);
      const responce = await UploadImage1(formData);
      console.log("Image upload response:", responce);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  const handleSubmit2 = async (e) => {
    e.preventDefault();

    if (!homeImage2) {
      alert("Please select a file first!");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("image", homeImage2);
      const responce = await UploadImage2(formData);
      console.log("Image upload response:", responce);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmit3 = async (e) => {
    e.preventDefault();

    if (!homeImage3) {
      alert("Please select a file first!");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("image", homeImage3);
      const responce = await UploadImage3(formData);
      console.log("Image upload response:", responce);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmit4 = async (e) => {
    e.preventDefault();

    if (!homeImage4) {
      alert("Please select a file first!");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("image", homeImage4);
      const responce = await UploadImage4(formData);
      console.log("Image upload response:", responce);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <Box
      style={{
        padding: "20px",
        backgroundColor: "#331D48",
        height: "100%",
      }}
    >
      <Typography variant="h6" component="h1" sx={{ color: "white" }}>
        CONTENT FOR HOME PAGE
      </Typography>

      <Grid
        container
        spacing={4}
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          flexWrap: "wrap",
          mt: 4,
        }}
      >
        {/* Box 1 */}
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              padding: 2,
              backgroundColor: "#f0f0f0",
              borderRadius: 2,
              boxShadow: 3,
              textAlign: "center",
              minHeight: "300px",
            }}
          >
            <Typography variant="h6">Image 1</Typography>
            <FormControl fullWidth>
              <Input
                type="file"
                onChange={handleImageChange1}
                sx={{ display: "none" }}
                id="upload-image-1"
              />
              <Button
                variant="contained"
                // color='primary'
                component="label"
                htmlFor="upload-image-1"
                sx={{ mt: 2, backgroundColor: "#331D48" }}
              >
                Upload
              </Button>
              <FormHelperText sx={{ color: "#9900ff" }}>
                {homeImage1 ? homeImage1.name : "No file chosen"}
              </FormHelperText>
            </FormControl>
            <Button
              variant="contained"
              // color='success'
              onClick={handleSubmit1}
              sx={{ mt: 10, backgroundColor: "#331D48" }}
            >
              Submit Image 1
            </Button>
          </Box>
        </Grid>

        {/* Box 2 */}
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              padding: 2,
              backgroundColor: "#f0f0f0",
              borderRadius: 2,
              boxShadow: 3,
              textAlign: "center",
              minHeight: "300px",
            }}
          >
            <Typography variant="h6">Image 2</Typography>
            <FormControl fullWidth>
              <Input
                type="file"
                onChange={handleImageChange2}
                sx={{ display: "none" }}
                id="upload-image-2"
              />
              <Button
                variant="contained"
                // color='primary'
                component="label"
                htmlFor="upload-image-2"
                sx={{ mt: 2, backgroundColor: "#331D48" }}
              >
                Upload
              </Button>
              <FormHelperText sx={{ color: "#9900ff" }}>
                {homeImage2 ? homeImage2.name : "No file chosen"}
              </FormHelperText>
            </FormControl>
            <Button
              variant="contained"
              // color='warning'
              onClick={handleSubmit2}
              sx={{ mt: 10, backgroundColor: "#331D48" }}
            >
              Submit Image 2
            </Button>
          </Box>
        </Grid>

        {/* Box 3 */}
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              padding: 2,
              backgroundColor: "#f0f0f0",
              borderRadius: 2,
              boxShadow: 3,
              textAlign: "center",
              minHeight: "300px",
            }}
          >
            <Typography variant="h6">Image 3</Typography>
            <FormControl fullWidth>
              <Input
                type="file"
                onChange={handleImageChange3}
                sx={{ display: "none" }}
                id="upload-image-3"
              />
              <Button
                variant="contained"
                // color='primary'
                component="label"
                htmlFor="upload-image-3"
                sx={{ mt: 2, backgroundColor: "#331D48" }}
              >
                Upload
              </Button>
              <FormHelperText sx={{ color: "#9900ff" }}>
                {homeImage3 ? homeImage3.name : "No file chosen"}
              </FormHelperText>
            </FormControl>
            <Button
              variant="contained"
              color="info"
              onClick={handleSubmit3}
              sx={{ mt: 10, backgroundColor: "#331D48" }}
            >
              Submit Image 3
            </Button>
          </Box>
        </Grid>

        {/* Box 4 */}
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              padding: 2,
              backgroundColor: "#f0f0f0",
              borderRadius: 2,
              boxShadow: 3,
              textAlign: "center",
              minHeight: "300px",
            }}
          >
            <Typography variant="h6">Map</Typography>
            <FormControl fullWidth>
              <Input
                type="file"
                onChange={handleImageChange4}
                sx={{ display: "none" }}
                id="upload-map"
              />
              <Button
                variant="contained"
                // color='primary'
                component="label"
                htmlFor="upload-map"
                sx={{ mt: 2, backgroundColor: "#331D48" }}
              >
                Upload
              </Button>
              <FormHelperText sx={{ color: "#9900ff" }}>
                {homeImage4 ? homeImage4.name : "No file chosen"}
              </FormHelperText>
            </FormControl>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit4}
              sx={{ mt: 10, backgroundColor: "#331D48" }}
            >
              Submit Map
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContentForHome;
