/** @format */

import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Input,
  FormControl,
  FormHelperText,
} from "@mui/material";
import {
  useGetServiceImage2Mutation,
  useServiceImage1Mutation,
  useServiceImage2Mutation,
  useServiceImage3Mutation,
} from "../../Store/Api/Main";

const ContentForServices = () => {
  const [servicesImage1, setServicesImage1] = useState(null);
  const [servicesImage2, setServicesImage2] = useState(null);
  const [multipleImageServices, setMultipleImageServices] = useState([]);
  const [UploadImage1] = useServiceImage1Mutation();
  const [UploadImage2] = useServiceImage2Mutation();
  const [UploadImage3] = useServiceImage3Mutation();

  // Separate change handlers for Image 1 and Image 2
  const handleServiceImage1Change = (e) => {
    const file = e.target.files[0];
    setServicesImage1(file);
  };

  const handleServiceImage2Change = (e) => {
    const file = e.target.files[0];
    setServicesImage2(file);
  };

  const handleMultipleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setMultipleImageServices((prev) => [...prev, ...files]);
    e.target.value = "";
  };

  const handleServicesImage1Submit = async () => {
    try {
      const formData = new FormData();
      formData.append("image", servicesImage1);
      const result = await UploadImage2(formData);
      console.log("servide image1 uploded:", result);
    } catch (error) {}
  };

  const handleServicesImage2Submit = async () => {
    try {
      if (servicesImage2) {
        console.log("Submitting Services Image 2:", servicesImage2);
      } else {
        console.log("No file selected for Services Image 2");
      }
      const formData = new FormData();
      formData.append("image", servicesImage2);
      const result = await UploadImage3(formData);
      console.log("servide image1 uploded:", result);
    } catch (error) {}
  };

  const handleMultipleImagesSubmit = async () => {
    if (multipleImageServices.length === 0) {
      alert("No images selected");
      return;
    }
    const formData = new FormData();
    multipleImageServices.forEach((file) => {
      formData.append("image", file);
    });

    try {
      const result = await UploadImage1(formData);
      console.log("Multiple Images:", result);
    } catch (error) {}
  };

  const renderUploadBox = (id, label, onChangeHandler, helperText) => (
    <FormControl fullWidth sx={{ mb: 2 }}>
      <Input
        id={id}
        type="file"
        multiple={id === "image-upload-Box"}
        onChange={onChangeHandler}
        sx={{ display: "none" }}
      />
      <Button
        component="label"
        variant="contained"
        color="primary"
        htmlFor={id}
        sx={{ mb: 2, backgroundColor: "#331D48" }}
      >
        {label}
      </Button>
      <FormHelperText sx={{ color: "#9900ff" }}>{helperText}</FormHelperText>
    </FormControl>
  );

  return (
    <Box
      style={{
        padding: "20px",
        // backgroundColor: '#331D48',
        height: "100%",
      }}
    >
      <Typography variant="h6" component="h1" sx={{ color: "white" }}>
        CONTENT FOR SERVICES PAGE
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", sm: "row", md: "row" },
          my: 4,
        }}
      >
        {/* Multiple Image Upload Box */}
        <Grid item xs={12} sm={6} md={4} lg={4} sx={{ width: "100%" }}>
          <Box
            sx={{
              padding: 2,
              backgroundColor: "#fff",
              borderRadius: 2,
              boxShadow: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "500px",
              minHeight: "300px",
            }}
          >
            {renderUploadBox(
              "image-upload-Box",
              "Upload Images for Box (select multiple images)",
              handleMultipleImageChange,
              multipleImageServices.length > 0
                ? `${multipleImageServices.length} files selected`
                : "No files chosen"
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleMultipleImagesSubmit}
              sx={{
                // width: '300px',
                mt: 8,
                backgroundColor: "#331D48",
                color: "white",
                // fontWeight: 'bold',
              }}
            >
              Submit Multiple Images
            </Button>
          </Box>
        </Grid>

        {/* Single Image 1 Upload Box */}
        <Grid item xs={12} sm={6} md={4} lg={4} sx={{ width: "100%" }}>
          <Box
            sx={{
              padding: 2,
              backgroundColor: "#fff",
              borderRadius: 2,
              boxShadow: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "500px",
              minHeight: "300px",
            }}
          >
            {renderUploadBox(
              "service-image-upload-1",
              "Upload Image 1",
              handleServiceImage1Change,
              servicesImage1 ? servicesImage1.name : "No file chosen"
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleServicesImage1Submit}
              sx={{
                // width: '300px',
                mt: 8,
                backgroundColor: "#331D48",
                color: "white",
                // fontWeight: 'bold',
              }}
            >
              Submit Image 1
            </Button>
          </Box>
        </Grid>

        {/* Single Image 2 Upload Box */}
        <Grid item xs={12} sm={6} md={4} lg={4} sx={{ width: "100%" }}>
          <Box
            sx={{
              padding: 2,
              backgroundColor: "#fff",
              borderRadius: 2,
              boxShadow: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "500px",
              minHeight: "300px",
            }}
          >
            {renderUploadBox(
              "service-image-upload-2",
              "Upload Image 2",
              handleServiceImage2Change,
              servicesImage2 ? servicesImage2.name : "No file chosen"
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleServicesImage2Submit}
              sx={{
                // width: '300px',
                mt: 8,
                backgroundColor: "#331D48",
                color: "white",
                // fontWeight: 'bold',
              }}
            >
              Submit Image 2
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContentForServices;
