/** @format */

import React, { useState } from 'react';
import Navbar from '../../Components/NavBar';
import { Box, Grid, Button } from '@mui/material';
import ContentForHome from './ContentForHome';
import ContentForServices from './ContentForServices';
import bgVideo from '../../Assets/BGVideo.mp4';

const AdminDashboard = () => {
  const [isHomeContent, setIsHomeContent] = useState(false);

  return (
    // <Box
    //   style={{
    //     padding: '20px',
    //     backgroundColor: '#331D48',
    //     // backgroundColor: 'red',

    //     height: '100%',
    //   }}>
    //   <Navbar
    //     mainHeading='Admin Dashboard'
    //     isAdmin={true}
    //   />
    //   <Grid
    //     container
    //     spacing={2}
    //     sx={{
    //       display: 'flex',
    //       justifyContent: 'space-around',
    //       alignItems: 'center',
    //       alignContent: 'center',
    //       flexDirection: { xs: 'column', sm: 'row', md: 'row' },
    //       my: 4,
    //     }}></Grid>

    //   {<ContentForHome />}
    //   {<ContentForServices />}
    // </Box>

    <Box
      sx={{
        position: 'relative',
        padding: '20px',
        height: '100%',
        overflow: 'hidden',
      }}>
      {/* Video Background */}
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover', // Ensures the video covers the entire area
          zIndex: -1, // Ensure the video is below the content
        }}>
        <source
          src={bgVideo}
          type='video/mp4'
        />
        Your browser does not support the video tag.
      </video>

      <Navbar
        mainHeading='Admin Dashboard'
        isAdmin={true}
      />

      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          alignContent: 'center',
          flexDirection: { xs: 'column', sm: 'row', md: 'row' },
          my: 4,
          zIndex: 1, // Ensure the content is above the video
        }}></Grid>

      {<ContentForHome />}
      {<ContentForServices />}
    </Box>
  );
};

export default AdminDashboard;
