/** @format */

import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import GTranslateRoundedIcon from '@mui/icons-material/GTranslateRounded';
import mainlogo from '../Assets/logo.png';
import { useNavigate } from 'react-router-dom';
import GoogleTranslate from './GoogleTranslate';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Twitter from '@mui/icons-material/Twitter';

const Navbar = ({ mainHeading, isAdmin }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigation = useNavigate();
  const [languageModalOpen, setLanguageModalOpen] = useState(false);

  const handleLanguageModal = () => {
    setLanguageModalOpen(!languageModalOpen);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleHome = () => {
    navigation('/');
  };

  const handleMetrithaServices = () => {
    navigation('/MetrithaServices');
  };

  const handleMetrithaAssociates = () => {
    navigation('/MetrithaAssociates');
  };

  const handleAdminDashboard = () => {
    navigation('/admin');
  };

  const handleAddNewNetwork = () => {
    navigation('/NewNetwork');
  };

  return (
    <AppBar
      position='static'
      sx={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginX: '2%',
          marginY: '1%',
          boxShadow: 'none',
        }}>
        {/* Hamburger menu for smaller screens */}
        <Box sx={{ display: { xs: 'block', sm: 'block' } }}>
          <IconButton
            edge='end'
            color='inherit'
            aria-label='menu'
            size='large'
            onClick={handleDrawerToggle}
            sx={{ color: '#f0f0f0' }}>
            <MenuIcon />
          </IconButton>
        </Box>
        <Box
          sx={{ display: { xs: 'block', sm: 'block' }, textAlign: 'center' }}>
          <img
            src={mainlogo}
            onClick={handleHome}
            alt='logo'
            style={{
              width: '100%',
              height: 'auto',
              maxWidth: { xs: '50%', sm: '70%', md: '60%' },
              objectFit: 'contain',
              cursor: 'pointer',
            }}
          />
        </Box>

        {/* Right Icon Button */}
        <Box
          sx={{
            display: { xs: 'block', sm: 'block', backgroundColor: 'red' },
          }}>
          <GoogleTranslate />
        </Box>
      </Toolbar>

      {/* Drawer for mobile */}
      <Drawer
        anchor='left'
        open={drawerOpen}
        onClose={handleDrawerToggle}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
            backgroundColor: '#331D48',
          }}>
          {/* Close Icon */}
          <IconButton
            onClick={handleDrawerToggle}
            sx={{ color: '#f0f0f0' }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <List
          sx={{
            width: {
              xs: 200,
              sm: 250,
              md: 350,
              lg: 400,
            },
            backgroundColor: '#331D48',
            height: '100%',
            paddingX: 3,
          }}>
          <Box
            component='img'
            src={mainlogo}
            alt='logo'
            sx={{
              width: {
                xs: '50%',
                sm: '60%',
                md: '60%',
                lg: '60%',
              },
              height: 'auto',
              maxWidth: '100%',
              mb: 5,
              ml: 7,
            }}
          />
          {isAdmin ? (
            <>
              <ListItem
                button
                onClick={handleAdminDashboard}>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        color: '#f0f0f0',
                        textAlign: 'center',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        fontSize: '1.2rem',
                      }}>
                      Dashboard
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem
                button
                onClick={handleAddNewNetwork}>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        color: '#f0f0f0',
                        textAlign: 'center',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        fontSize: '1.2rem',
                      }}>
                      Add New Network
                    </Typography>
                  }
                />
              </ListItem>
            </>
          ) : (
            <>
              <ListItem
                button
                onClick={handleHome}>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        color: '#f0f0f0',
                        textAlign: 'center',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        fontSize: '1.2rem',
                        '&:hover': {
                          backgroundColor: '#ffffff', // White background on hover
                          borderRadius: '20px',
                          color: '#6a1b9a', // Purple text color on hover
                        },
                      }}>
                      Home
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                button
                onClick={handleMetrithaServices}
                sx={{ marginY: 3 }}>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        color: '#f0f0f0',
                        textAlign: 'center',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        fontSize: '1.2rem',
                        '&:hover': {
                          backgroundColor: '#ffffff', // White background on hover
                          borderRadius: '20px',
                          color: '#6a1b9a', // Purple text color on hover
                        },
                      }}>
                      Services
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem
                button
                onClick={handleMetrithaAssociates}
                sx={{ marginY: 3 }}>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        color: '#f0f0f0',
                        textAlign: 'center',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        fontSize: '1.2rem',
                        '&:hover': {
                          backgroundColor: '#ffffff', // White background on hover
                          borderRadius: '20px',
                          color: '#6a1b9a', // Purple text color on hover
                        },
                      }}>
                      Network
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  marginTop: '100px',
                }}>
                <IconButton
                  component='a'
                  href='https://www.linkedin.com/company/metritha/'
                  target='_blank'
                  sx={{
                    color: '#f0f0f0',
                  }}>
                  <LinkedInIcon
                    sx={{
                      fontSize: { xs: '6rem', sm: '6rem', md: '8rem' }, // Set font size directly on the icon
                    }}
                  />
                </IconButton>
              </ListItem>
            </>
          )}
        </List>
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
