/** @format */

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
} from '@mui/material';
import associateImg from '../Assets/associateImg.png';
import Navbar from '../Components/NavBar';
import SearchIcon from '@mui/icons-material/Search';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import MetrithaCapitalPartners from '../Assets/MetrithaCapitalPartners.jpg';
import TrustLogo from '../Assets/TrustLogo.png';
import Santini from '../Assets/Santini.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import bottomImg from '../Assets/bottomImg.png';
import ContactBtn from '../Components/ContactBtn';
import Footer from '../Components/Footer';
import { useAllNetworkMutation } from '../Store/Api/Main';

const MetrithaAssociates = () => {
  const [open, setOpen] = useState(false);
  const [SearchValue, setSearchValue] = useState('');
  const [selectCountry, setSelectCountry] = useState('');
  const [isAllNetworks, setAllNetworks] = useState([]);
  const [allNetworks] = useAllNetworkMutation();

  const handleClose = () => setOpen(false);

  const handleCountryChange = (event) => {
    setSelectCountry(event.target.value);
  };

  const handleSeacrhChange = (event) => {
    setSearchValue(event.target.value);
  };

  const getAllNetworks = async () => {
    try {
      const response = await allNetworks();
      if (Array.isArray(response?.data?.data)) {
        await setAllNetworks(response?.data?.data);
      } else {
        console.error('Response is not an array:', response);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getAllNetworks();
  }, []);

  const companies = [
    {
      id: 1,
      name: 'MS Santini Multifamily Office',
      discription:
        'With slightly over 20 years of presence and experience in the market, MS SANTINI operates in the segments of accounting, tax and corporate structuring matters, by conducting outsourcing, diligences and audits as well as by exercising the role of a facilitating agent to the foreign investor’s comprehension of the business environment in Brazil, which we call ‘Start up Solution’. In addition, we are highly specialized in assisting family groups and companies – including on succession planning – while we continuously look for strategic solutions for the projects of a most varied category of clients.',
      type: 'Shareholder',
      img: Santini,
      websiteLink: 'https://www.mssantini.com/',
      country: 'Brazil',
    },
    {
      id: 2,
      name: 'Metritha Capital Partners',
      discription:
        'Metritha is an Investment Banking firm specialized in supporting Colombian clients in the sale and/or acquisition of companies, mergers, joint ventures, strategic capital and structured debt raising, project planning and financial closing, and multipurpose financial valuation processes.',
      type: 'Shareholder',
      img: MetrithaCapitalPartners,
      websiteLink: 'https://metritha.com.co/',
      country: 'Colombia',
    },
    {
      id: 3,
      name: 'Traust Accounting and Legal Firm ',
      discription:
        'Traust Accounting is the effort of more than 100 people working along with your team. Our firm began in 1973, under the personal name of CPA Jesus Mario Treviño Sepúlveda. In 1983, we became the Office Sepúlveda Treviño S.C., and have been growing ever since. Today, we bring the experience of over 40 years in business to the accounting industry, helping companies and individuals make the best decisions in matters related to tax, audit, accounting and finance.',
      type: 'Shareholder',
      img: TrustLogo,
      websiteLink: 'https://accountingfirm.mx',
      country: 'Mexico',
    },
    {
      id: 4,
      name: 'HCC',
      discription:
        'HCC Accountants / Tax Advisors specializes in the provision of Advisory and Accounting services to a wide range of businesses in Aruba, Curacao, St. Maarten and beyond. We provide client-focused, tech-enabled and human-centric services that help drive business forward.',
      type: ' Partner',
      img: null,
      websiteLink: 'https://metritha.com.co/',
      country: 'Aruba',
    },
    {
      id: 5,
      name: 'Madera Soriano y Asociados',
      discription:
        'The firm was founded in 1974, under the original name of Cordero, Madera & Asociados, which was changed in 1993, to F. Madera, Soriano and Associates. Since its inception, it has been registered with the Institute of Authorized Public Accountants of the Dominican Republic, the body that governs accounting in the country, where it maintains an active participation, collaborating and supporting the different actions of the institution. Our senior partner served as chair of the ICPARD board twice. In 2000, Patricia Alexandra Madera Soriano joined the company as a partner, after more than 10 years in the firm and previous work experience in an international firm, who currently has the responsibility of the office management.',
      type: 'Partner',
      websiteLink: 'https://metritha.com.co/',
      country: null,
    },
    {
      id: 6,
      name: ' Sacoto & Asociados',
      discription:
        'Sacoto & Asociados Cia. Ltda. was founded in 1983, the result of the initiative of Econ. C.P.A. Efrain Sacoto Salazar and a group of successful and visionary professionals with a desire to transmit their experiences to business. All partners and associates of Sacoto & Associados Cia. Ltd. have extensive work experience and a solid educational background. In addition, the firm’s professional staff covers main areas of business activity. Our firm has been gradually achieving its growth and is currently ranked as the Number 1 firm in the southern part of Ecuador. The performance of our work based on the City of Cuenca is extended to the rest of the country. The leadership in providing services of our firm is the result of applying high quality standards, which meet the requirements of the profession globally.',
      type: 'Partner',
      img: null,
      websiteLink: 'https://metritha.com.co/',
      country: 'Ecuador',
    },
    {
      id: 7,
      name: ' Pinther, Bassus & Asociados',
      discription:
        'With over 25 years of experience, Pinther, Bassus & Asociados is a full-service CPA firm, helping businesses meet their objectives and obligations. We are not a typical accounting firm. We approach each client engagement as an advisor and bring our best advice, our strongest technical skills, and a client support team that is available around the clock to meet our clients needs. Our principle: to offer a high level of personal service to our clients.',
      type: 'Partner',
      img: null,
      websiteLink: null,
      country: 'Argentina',
    },
    {
      id: 8,
      name: 'PCG Auditores Consultores',
      discription:
        'PCG Auditores Consultores is a company founded in 2007, whose corporate purpose is the provision of professional services in the areas of Auditing, Accounting, IT, Human Resources and Taxes, as well as scientific research and teaching.',
      type: 'Partner',
      img: null,
      websiteLink: null,
      country: 'Paraguay',
    },
    {
      id: 9,
      name: 'Castillo Sánchez & Asociados',
      discription:
        'Castillo Sánchez & Asociados S. Civil de RL, is a professional organization of accountants, lawyers and consultants, established in Peru, in accordance with the laws of the Republic of Peru. We are oriented to offer comprehensive services to companies in any sector and dimension, with particular emphasis on financial consultancy, strategy, organization, operational management and development of business plans.',
      type: 'Partner',
      img: null,
      websiteLink: null,
      country: 'Chile',
    },
    {
      id: 10,
      name: ' Keystone Auditores y Consultores',
      discription:
        'Keystone Auditores Chile was founded in 1997 and has grown under the leadership of three of its founding partners, with the support of additional partners who have brought focused expertise in their respective business areas. The firm has 33 professionals in multiple service areas, each led by a specialist partner. Keystone Auditores Chile has rich experience in the field of business consultancies and audits and the integral management of consultancies. The firm focusses on supporting clients in strategic operational areas, including human resources, tax and legal services among others. Keystone Auditores Chile also utilizes outsourced professionals who have experience supporting the firm for many years, to ensure a full service is provided to clients by a team of trusted professionals. At Keystone Auditores Chile, the goal is to become a true strategic ally to clients to ensure their long-term success.',
      type: 'Partner',
      img: null,
      websiteLink: null,
      country: 'Ecuador',
    },
    {
      id: 11,
      name: ' Aena Auditores y Consultores',
      discription:
        'Aena Auditores y Consultores is a local Ecuadorian firm, with international experience, generating solutions since 1990. The firm provides services which include external audits of financial statements, outsourced internal/operational audits, management consulting services and tax advisory services in over 60 business industries and sectors. The firm provides audit, advisory, consulting and tax services to multinational and national companies based in Ecuador and in other countries and regions.',
      type: 'Partner',
      img: null,
      websiteLink: null,
    },
    {
      id: 12,
      name: ' Rabbordi y Asociados',
      discription:
        'Schinca, Rabbordi y Asociados provides services of the highest quality within the country and matches them, in terms of coverage and diversity, to the needs of our clients. It is our mission that each client is provided with continuous and personalized service. A close follow-up of the business development, the satisfaction of the clients needs for professional services, and the fulfillment of the expected results, are our main priorities. Our professional attitude towards our clients is aimed at becoming management advisors, based on a global notion of the company, without being limited to a technical-professional duty in specific areas. We do not want our clients to feel our services are just transactional and designed to meet basic needs.',
      type: 'Partner',
      img: null,
      websiteLink: null,
      country: 'Argentina',
    },
    {
      id: 13,
      name: 'Ventura Sosa',
      discription:
        'Ventura Sosa, its name has been honored with the surnames of the parents of its founder and legal representative, whose ancestral value translates into the integrity and suitability of its audit and business consulting services to Salvadorian society.',
      type: 'Partner',
      img: null,
      websiteLink: null,
      country: 'El Salvador',
    },
  ];

  // const filteredCompanies = SearchValue.trim()
  //   ? Companies.filter((company) =>
  //       company.name.toLowerCase().includes(SearchValue.toLowerCase())
  //     )
  //   : Companies;
  const filteredCompanies = isAllNetworks.filter((company) => {
    const matchesSearch =
      SearchValue.trim() === '' ||
      company.networkName.toLowerCase().includes(SearchValue.toLowerCase());

    const matchesCountry =
      selectCountry === '' || company.country === selectCountry;

    return matchesSearch && matchesCountry;
  });

  const handleWebsiteClick = (Link) => {
    if (!Link) {
      return alert('No website available');
    }

    window.open(Link, '_blank');
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100vh',
      }}>
      <Box
        ox
        sx={{
          width: '100%',
          height: '100%',
          backgroundImage: `url(${associateImg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}>
        {/* Background Overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1,
          }}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 2,
        }}>
        <Navbar mainHeading='Admin Dashboard' />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 3,
          textAlign: 'center',
        }}>
        <Typography
          variant='h1'
          sx={{
            color: '#fff',
            fontFamily: 'Poppins',
            fontWeight: 'lighter',
            fontSize: { xs: '40px', sm: '65px', md: '90px' },
            letterSpacing: { xs: '2px', sm: '3px', md: '5px' },
          }}>
          Network
        </Typography>
      </Box>
      <Box style={{ padding: '20px', backgroundColor: '#331D48' }}>
        <Box
          style={{}}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}>
          <Typography
            sx={{
              color: '#f0f0f0',
              fontFamily: 'Poppins',
              fontWeight: '300',
              fontSize: {
                xs: '1rem',
                sm: '1rem',
                md: '1.5rem',
                lg: '2.2rem',
              },
              letterSpacing: { xs: '2px' },
              padding: { xs: '20px', sm: '60px', md: '30px' },
              textAlign: 'center',
            }}>
            SHAREHOLDERS & PARTNERS
          </Typography>
        </Box>
        <Box
          sx={{
            width: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            flexDirection: 'column',
            margin: 'auto',
          }}>
          <TextField
            fullWidth
            id='fullWidth'
            variant='outlined'
            placeholder='Search'
            onChange={handleSeacrhChange}
            value={SearchValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon sx={{ color: 'white' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '50px',
                '& fieldset': {
                  borderColor: 'white',
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'white',
              },
              '& .MuiOutlinedInput-input': {
                color: 'white',
              },
            }}
          />
        </Box>
        <Box
          sx={{
            width: { xs: '50%', sm: '30%', md: '20%' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            flexDirection: 'row',
            margin: 'auto',
            padding: '30px',
          }}>
          <FormControl fullWidth>
            <InputLabel
              id='demo-simple-select-label'
              sx={{
                color: 'white',
                '&.Mui-focused': {
                  color: 'white',
                },
              }}>
              View a country{' '}
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={selectCountry}
              label='View a country'
              onChange={handleCountryChange}
              sx={{
                color: 'white',
                '.MuiOutlinedInput-root': {
                  borderRadius: '50px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white',
                },
                '& .MuiSvgIcon-root': {
                  color: 'white',
                },
              }}>
              <MenuItem value=''>All Countries</MenuItem>
              <MenuItem value='Argentina'>Argentina</MenuItem>
              <MenuItem value='El Salvador'>El Salvador</MenuItem>
              <MenuItem value='Paraguay'>Paraguay</MenuItem>
              <MenuItem value='Chile'>Chile</MenuItem>
              <MenuItem value='Ecuador'>Ecuador</MenuItem>
              <MenuItem value='Aruba'>Aruba</MenuItem>
              <MenuItem value='Brazil'>Brazil</MenuItem>
              <MenuItem value='Colombia'>Colombia</MenuItem>
              <MenuItem value='Maxico'>Maxico</MenuItem>
            </Select>
          </FormControl>

          <SwapVertIcon sx={{ color: 'white', marginLeft: '10px' }} />
        </Box>

        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            textAlign: 'center',
            flexDirection: 'row',
            margin: 'auto',
            pb: '50px',
            gap: '35px',
          }}>
          {filteredCompanies.map((company) => (
            <Grid
              key={company?._id}
              container
              spacing={2}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                textAlign: 'center',
                flexDirection: 'row',
                // border: '2px solid #0096FF',
                padding: '20px',
              }}>
              {/* Company Logo and Name */}
              <Grid
                item
                xs={12}
                sm={12}
                md={3}>
                <img
                  src={company?.image}
                  alt={''}
                  style={{ width: '30%' }}
                />
                {/* <Typography
                  variant='h1'
                  sx={{
                    color: 'white',
                    fontFamily: 'Poppins',
                    fontWeight: '300',
                    fontSize: 16,
                    pt: 1.5,
                  }}>
                  {company.name}
                </Typography> */}
              </Grid>

              {/* Company Description */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  textAlign: 'left',
                  flexDirection: 'column',
                }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography
                    variant='h1'
                    sx={{
                      color: 'white',
                      fontFamily: 'Poppins',
                      fontWeight: '500',
                      fontSize: {
                        xs: '14px',
                        sm: '14px',
                        md: '16px',
                        lg: '18px',
                      },
                      py: 2,
                      textAlign: 'left',
                      mr: 5,
                    }}>
                    {company?.networkName}
                  </Typography>
                  <Typography
                    variant='h1'
                    sx={{
                      color: 'white',
                      fontFamily: 'Poppins',
                      fontWeight: '500',
                      fontSize: {
                        xs: '14px',
                        sm: '14px',
                        md: '16px',
                        lg: '18px',
                      },
                      py: 2,
                      textAlign: 'left',
                    }}>
                    {company?.country}
                  </Typography>
                </Box>
                {/* <Typography
                  variant='h1'
                  sx={{
                    color: 'white',
                    fontFamily: 'Poppins',
                    fontWeight: '500',
                    fontSize: {
                      xs: '14px',
                      sm: '14px',
                      md: '16px',
                      lg: '18px',
                    },
                    py: 2,
                    textAlign: 'left',
                  }}>
                  Website:{' '}
                  {(() => {
                    try {
                      // Prepend http:// if no protocol exists and return the hostname
                      return new URL(
                        company.websiteLink.startsWith('http')
                          ? company.websiteLink
                          : `http://${company.websiteLink}`
                      ).hostname;
                    } catch (error) {
                      console.error('Invalid URL:', company.websiteLink);
                      return ''; // Fallback if URL is invalid
                    }
                  })()}
                </Typography> */}
                {/* <Typography
                  variant='h1'
                  sx={{
                    color: 'white',
                    fontFamily: 'Poppins',
                    fontWeight: '500',
                    fontSize: {
                      xs: '14px',
                      sm: '14px',
                      md: '16px',
                      lg: '18px',
                    },
                    py: 2,
                    textAlign: 'left',
                  }}>
                  Discription:
                </Typography> */}
                <Typography
                  variant='subtitle1'
                  sx={{
                    color: 'white',
                    fontFamily: 'Poppins',
                    fontWeight: '300',
                    fontSize: {
                      xs: '14px',
                      sm: '16px',
                      md: '18px',
                      lg: '20px',
                    },
                    letterSpacing: { xs: '1px', sm: '1px', md: '1px' },
                  }}>
                  {company?.description}
                </Typography>
              </Grid>
              {/* Button */}
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                gap={{ xs: '10px', sm: '15px', md: '35px' }}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'left',
                  flexDirection: 'column',
                }}>
                <Typography
                  variant='h1'
                  sx={{
                    color: 'white',
                    fontFamily: 'Poppins',
                    fontWeight: '500',
                    fontSize: {
                      xs: '14px',
                      sm: '14px',
                      md: '16px',
                      lg: '18px',
                    },
                    pt: 1.5,
                  }}>
                  {company?.type?.toUpperCase()}
                </Typography>
                <Button
                  variant='outlined'
                  onClick={() => {
                    handleWebsiteClick(company?.websiteUrl);
                  }}
                  sx={{
                    color: 'white',
                    borderColor: 'white',
                    borderRadius: '50px',
                    '&:hover': {
                      borderColor: 'white',
                    },
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  Website
                  <ArrowForwardIcon
                    sx={{ color: 'white', marginLeft: '8px' }}
                  />
                </Button>
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Grid
          container
          spacing={2}
          backgroundColor={'#331D48'}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              position: 'relative',
            }}>
            {/* Image */}
            <img
              src={bottomImg}
              alt='logo'
              style={{
                width: '80%',
                height: '80%',
                maxWidth: '100%',
              }}
            />

            {/* Overlay Content */}
            <Box
              sx={{
                position: 'absolute',
                top: '30%', // Adjust to center better on small screens
                left: '50%',
                transform: 'translate(-50%, -50%)', // Adjust position
                textAlign: 'center',
                color: '#fff', // Text color
                zIndex: 1, // Ensure text is above the image
                padding: { xs: '10px', sm: '20px' }, // Padding for mobile
                width: { xs: '100%', sm: '80%', md: '60%' }, // Responsive width
              }}>
              <Typography
                variant='h1'
                sx={{
                  fontWeight: 'lighter',
                  fontFamily: 'Poppins',
                  color: '#f0f0f0',
                  fontSize: { xs: '12px', sm: '20px', md: '25px', lg: '40px' },
                  letterSpacing: { xs: '2px', sm: '3px', md: '5px' },
                }}>
                Interested in our portfolio?
              </Typography>
            </Box>

            <Box
              sx={{
                position: 'absolute',
                top: { xs: '70%', sm: '65%', md: '60%' },
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1,
                width: { xs: '80%', sm: '55%', md: '90%', lg: '100%' },
                // height: '50%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '15px',
              }}>
              <ContactBtn
                open={open}
                handleClose={handleClose}
                setWidth={{ xs: '10%', sm: '10%', md: '50%', lg: '100%' }}
              />

              <Typography
                variant='body1'
                sx={{
                  fontFamily: 'Poppins',
                  color: '#f0f0f0',
                  marginTop: '15px',
                  display: {
                    xs: 'none',
                    sm: 'flex',
                    md: 'flex',
                    lg: 'flex',
                  },
                }}>
                We will call you back in..
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Button
            variant='outlined'
            sx={{
              backgroundColor: '#331D48',
              color: '#f0f0f0',
              borderRadius: '20px',
              padding: '10px 20px',
              border: '1px solid #f0f0f0',
              '&:hover': {
                backgroundColor: '#ffffff', // White background on hover
                color: '#6a1b9a', // Purple text color on hover
                border: '1px solid #6a1b9a', // Purple border on hover
              },
            }}>
            download brochure
          </Button>
        </Grid>
        <Footer />
      </Box>
    </Box>
  );
};

export default MetrithaAssociates;
