/** @format */

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../services/Urls";

export const UserAuth = createApi({
  reducerPath: "userAuth",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    newNetwork: builder.mutation({
      query: (data) => ({
        url: "/NewNetwork",
        method: "POST",
        body: data,
      }),
    }),
    allNetwork: builder.mutation({
      query: (data) => ({
        url: "/getAllNetworks",
        method: "GET",
        body: data,
      }),
    }),
    HomeImage1: builder.mutation({
      query: (data) => ({
        url: "/homeImage1",
        method: "POST",
        body: data,
      }),
    }),
    HomeImage2: builder.mutation({
      query: (data) => ({
        url: "/homeImage2",
        method: "POST",
        body: data,
      }),
    }),
    HomeImage3: builder.mutation({
      query: (data) => ({
        url: "/homeImage3",
        method: "POST",
        body: data,
      }),
    }),
    HomeImage4: builder.mutation({
      query: (data) => ({
        url: "/homeImage4",
        method: "POST",
        body: data,
      }),
    }),
    GetImage1: builder.mutation({
      query: (data) => ({
        url: "/getImage1",
        method: "GET",
        body: data,
      }),
    }),
    GetImage2: builder.mutation({
      query: (data) => ({
        url: "/getImage2",
        method: "GET",
        body: data,
      }),
    }),
    GetImage3: builder.mutation({
      query: (data) => ({
        url: "/getImage3",
        method: "GET",
        body: data,
      }),
    }),
    GetImage4: builder.mutation({
      query: (data) => ({
        url: "/getImage4",
        method: "GET",
        body: data,
      }),
    }),
    ServiceImage1: builder.mutation({
      query: (data) => ({
        url: "/serviceImage1",
        method: "POST",
        body: data,
      }),
    }),
    ServiceImage2: builder.mutation({
      query: (data) => ({
        url: "/serviceImage2",
        method: "POST",
        body: data,
      }),
    }),
    ServiceImage3: builder.mutation({
      query: (data) => ({
        url: "/serviceImage3",
        method: "POST",
        body: data,
      }),
    }),

    GetServiceImage1: builder.mutation({
      query: (data) => ({
        url: "/getServiceImage1",
        method: "GET",
        body: data,
      }),
    }),
    GetServiceImage2: builder.mutation({
      query: (data) => ({
        url: "/getServiceImage2",
        method: "GET",
        body: data,
      }),
    }),
    GetServiceImage3: builder.mutation({
      query: (data) => ({
        url: "/getServiceImage3",
        method: "GET",
        body: data,
      }),
    }),
  }),
});
export const {
  useNewNetworkMutation,
  useAllNetworkMutation,
  useHomeImage1Mutation,
  useHomeImage2Mutation,
  useHomeImage3Mutation,
  useHomeImage4Mutation,
  useGetImage1Mutation,
  useGetImage2Mutation,
  useGetImage3Mutation,
  useGetImage4Mutation,
  useServiceImage1Mutation,
  useServiceImage2Mutation,
  useServiceImage3Mutation,
  useGetServiceImage1Mutation,
  useGetServiceImage2Mutation,
  useGetServiceImage3Mutation,
} = UserAuth;
